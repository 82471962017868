/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, RobotoDraft, Arial, sans-serif; }



.mat-drawer-inner-container {
    min-width: 275px !important;
}

.loadingCircle{
    width: 100%;
    text-align: center;
    margin-top: 150px;
}

.app-primary-color{
    color: #4285f4 !important;
}

.global-progress-bar{
    position: fixed !important;
    z-index: 999;
}

.dashboard-content{
    color: rgba(0,0,0,.87);
    padding: 20px;
    font-family: Google Sans, sans-serif;
    font-weight: 400 !important;
 }

.hover-blue:hover{
    color:green
}

.hover-red:hover{
    color:red;
    cursor: pointer;
}

.mat-dialog-cancel{
   top: -12px;
   float: right;
   color: #1a73e8;
   right: -12px;
}

.mat-dialog-info{
   font-size: 13px;
   letter-spacing: .2px;
   color: rgba(0,0,0,.87);
}